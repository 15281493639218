<template>
  <div class='container'>
    <van-nav-bar title="历史主诉列表" left-arrow fixed placeholder @click-left="close" />
    <div v-if="tableData.length>0">
      <div class="con" v-for="(item,index) in tableData" :key="index">
        <el-descriptions title="" :column="2" border>
          <el-descriptions-item label="id:">{{item.oid}}</el-descriptions-item>
          <el-descriptions-item label="客户:">{{item.createUserName}}</el-descriptions-item>
          <el-descriptions-item label="诉求:" :span="2">{{item.demandContent}}</el-descriptions-item>
          <el-descriptions-item label="录入人:">{{item.updateUserName}}</el-descriptions-item>
          <el-descriptions-item label="录入时间:">{{item.createTime}}</el-descriptions-item>
        </el-descriptions>
      </div>
      <el-pagination style="text-align: right;" background layout="prev, pager, next" :total="totalSize"
        @current-change="changepage">
      </el-pagination>
    </div>
    <el-empty v-else></el-empty>
  </div>
</template>

<script>
  // 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
  // 例如：import 《组件名称》 from '《组件路径》'
  import homeManager from "@/request/homeManager.js";
  export default {
    // import引入的组件需要注入到对象中才能使用
    components: {},
    data () {
      // 这里存放数据
      return {
        requst: {
          module: '',
          url: '',
        },
        query: {},
        tableData: [],
        activeNames: '1',
        dataInfo: [],
        pageSize: 10,
        currentPage: 1,
        totalSize: 0,

      }
    },
    // 监听属性 类似于data概念
    computed: {},
    // 监控data中的数据变化
    watch: {},
    // 生命周期 - 创建完成（可以访问当前this实例）
    created () {
      this.chiefSearch()
    },
    // 生命周期 - 挂载完成（可以访问DOM元素）
    mounted () { },
    // 方法集合
    methods: {
      changepage (val) {
        this.currentPage = val
        this.chiefSearch()
      },
      async chiefSearch () {
        try {
          let { code, data } = await homeManager.chiefSearch({ pageSize: this.pageSize, currentPage: this.currentPage, custId: this.$store.getters.custId })
          if (code == 200) {
            this.tableData = data.dataList
            this.totalSize = data.totalSize
            console.log(data)
          }
        } catch (error) {
          //
        }
      },
      close () {
        this.$router.go(-1);
      },
    },
    // 生命周期 - 创建之前
    beforeCreate () { },
    // 生命周期 - 挂载之前
    beforeMount () { },
    // 生命周期 - 更新之前
    beforeUpdate () { },
    // 生命周期 - 更新之后
    updated () { },
    // 生命周期 - 销毁之前
    beforeDestroy () { },
    // 生命周期 - 销毁完成
    destroyed () { },
    //  // 如果页面有keep-alive缓存功能，这个函数会触发
    activated () { },
  }
</script>

<style scoped>
  .container {
    height: 100%;
    padding: 20px;
    box-sizing: border-box;
  }

  .header {
    margin: 20px 0;
  }

  .header span {
    font-size: 30px;
    font-weight: 600;
  }

  .btn {
    font-size: 20px;
    margin-left: 10px;
  }

  .con {
    background: #fff;
    box-sizing: border-box;
    font-size: 24px;
    margin-bottom: 20px;
  }

  .marDan {
    margin-bottom: 10px;
  }

  .goods {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .goodsImg {
    width: 150px;
    height: 150px;
    border-radius: 10px;
    background: sandybrown;
    margin-right: 20px;
  }

  .flooter {
    display: flex;
    justify-content: flex-end;
  }

  .el-descriptions {
    font-size: 20px;
  }

  ::v-deep .el-pagination {
    font-size: 20px;
  }
</style>