var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("van-nav-bar", {
        attrs: {
          title: "历史主诉列表",
          "left-arrow": "",
          fixed: "",
          placeholder: "",
        },
        on: { "click-left": _vm.close },
      }),
      _vm.tableData.length > 0
        ? _c(
            "div",
            [
              _vm._l(_vm.tableData, function (item, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "con" },
                  [
                    _c(
                      "el-descriptions",
                      { attrs: { title: "", column: 2, border: "" } },
                      [
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "id:" } },
                          [_vm._v(_vm._s(item.oid))]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "客户:" } },
                          [_vm._v(_vm._s(item.createUserName))]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "诉求:", span: 2 } },
                          [_vm._v(_vm._s(item.demandContent))]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "录入人:" } },
                          [_vm._v(_vm._s(item.updateUserName))]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "录入时间:" } },
                          [_vm._v(_vm._s(item.createTime))]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
              _c("el-pagination", {
                staticStyle: { "text-align": "right" },
                attrs: {
                  background: "",
                  layout: "prev, pager, next",
                  total: _vm.totalSize,
                },
                on: { "current-change": _vm.changepage },
              }),
            ],
            2
          )
        : _c("el-empty"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }